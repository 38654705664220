<template>
	<main>
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<div class="row align-items-center">
								<div class="col"></div>
								<div class="col">
									<button @click.prevent="init_component" class="btn btn-primary btn-block">{{ $t('global.go_pax') }}</button>
								</div>
								<div class="col"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">
	import UserMixin from "@/mixins/User.js"

	export default {
		name: "HorseFiche",
		mixins: [
            UserMixin
        ],
		data () {
			return {
			}
		},
		created() {
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				window.open(
					"http://pax.gfeweb.com/fr/?autolog_groomy=" + this.getUserMailEncoded(),
					'_blank'
				);
			},
		},
		computed: {
            
		},
        watch: {
        },
		components: {
			HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
		}
	}
</script>
